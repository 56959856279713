@import '../../../assets/styles/mixins';

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 14px;

  @media (max-width: $tablet) {
    justify-content: center;
  }
}

.grid::after {
  content: '';
  flex: auto;
}
