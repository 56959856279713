@import '../../../assets/styles/mixins';

.container {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;

  @media (max-width: $tablet) {
    flex-direction: column;
    justify-content: center;
  }
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardWidth {
  width: 380px;
}
