.modal {
    background: rgba(0, 0, 0, 0.541);
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(5px);
    transition: opacity 0.3s ease;
    opacity: 0;
    z-index: -1;
}
.modal_opened {
    z-index: 99;
    opacity: 1;
}
