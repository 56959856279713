@import '../../../assets/styles/variables';

.header__button {
  @media (max-width: $tablet) {
    display: none;
  }
}

.header__link {
  text-decoration: none;
}
.header__actions {
  align-items: center;

  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 16px;
}