@import '../../../assets/styles/global';
@import '../../../assets/styles/mixins';

.NavTab {
  cursor: pointer;
  transition: 0.4s;
  background: transparent;
  border-radius: 10px;
  padding: 8px 12px;
  @include heading16($basicWhite);
  align-items: center;
  display: flex;
  > svg {
    margin-right: 4px;
  }
  &:hover {
    transition: 0.4s;
    background: $pink;
  }
  &.active {
    color: $purple;
    cursor: default;
  }
}
