@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.mb30 {
  margin-bottom: 30px;
}

.Row {
  display: flex;
  width: 100%;
  margin-bottom: 64px;

  @media (max-width: $tabletSmall) {
    flex-direction: column;
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  .button_wrap {
    width: 100%;
    @media (max-width: $mobile) {
      margin-bottom: 24px;
      & > button {
        width: 100%;
      }
    }
  }
  .Column {
    flex: 1 1 50%;
    &:first-child {
      margin-right: 40px;
      @media (max-width: $tabletSmall) {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

    .socials {
      > *:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .Title {
      margin-bottom: 12px;
      @include subheading24($basicWhite);
    }

    .Description {
      margin: 0;
      @include body20($grayscale);
      &.SecondType {
        color: $basicWhite;
      }
    }

    .SubDescription {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #6f6767;
    }

    .FieldWrap {
      div {
        padding: 0;
      }
    }
  }
}

.textarea__count {
  display: flex;
  justify-content: end;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #6f6767;
}

.upload__input {
  opacity: 0 !important;
  position: absolute;
  z-index: -1;
}
.title__verify {
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}
.verify__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: OpenSans-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #221e1e;
  box-shadow: 0 2px 8px rgba(34, 30, 30, 0.16);
  border-radius: 48px;
  padding: 12px 0;
  margin-bottom: 24px;
  cursor: pointer;
  text-decoration: none;
}
.verify__icon {
  margin-right: 10px;

  path {
    fill: #000;
    fill-opacity: 1;
  }
}
.verify__verifyIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.resultButton {
  width: 100%;
}
.buttons {
  margin-top: 60px;
}
