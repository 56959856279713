@import '../../../assets/styles/mixins';

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;

  @include heading20($basicWhite);
}

.description {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @include body16($basicWhite);
}
