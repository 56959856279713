@import '../../../assets/styles/mixins';

.more_wrap {
  position: relative;
  max-width: 32px;
  z-index: 10;

  .more__button {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    background-color: transparent;
    box-shadow: 0 0 24px rgba(60, 54, 54, 0.16);
    border-radius: 48px;
    cursor: pointer;
    border: 2px solid #c400ff;

    svg {
      width: 10px;
    }
  }

  .more__dropdown {
    position: absolute;
    bottom: 120%;
    right: 0;
    padding: 8px;
    width: 195px;
    background: $basicDark;
    border: 2px solid $purple2;
    box-shadow: $uploadCardBoxShadow;
    box-shadow: 0 2px 8px rgba(34, 30, 30, 0.16);
    border-radius: 4px;

    .more__dropdown_item {
      display: flex;
      align-items: center;
      height: 40px;
      margin-bottom: 8px;
      gap: 12px;
      cursor: pointer;
      transition: 0.2s;
      border-radius: 4px;
      white-space: nowrap;
      padding: 8px;

      @include heading16($basicWhite);

      &:hover {
        background-color: $basicWhite;
        color: $basicDark;
        transition: 0.2s;

        path {
          fill: $basicDark;
          fill-opacity: 1;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        width: 20px;
      }

      path {
        fill: $basicWhite;
        fill-opacity: 1;
      }

      &Disabled {
        color: $grayscale;

        path {
          fill: $grayscale;
          fill-opacity: 1;
        }

        &:hover {
          background-color: transparent;
          color: $grayscale;

          path {
            fill: $grayscale;
            fill-opacity: 1;
          }
        }
      }
    }
  }
}

.warning {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;
  background-color: $pink;

  @include heading16($basicWhite);

  svg {
    min-width: 20px;
  }

  path {
    fill: #f9f9f9;
  }
}

.tooltipStyles {
  padding: 8px !important;
  max-width: 175px !important;
  background-color: #fff !important;
  opacity: 1 !important;
  @include highlight14($basicDark);
  color: $basicDark !important;
}
