@import '../../../assets/styles/mixins';

.nft__details_wrap {
  display: flex;
  gap: 40px;
  margin-top: 24px;

  @media (max-width: $tablet) {
    flex-direction: column;
    padding: 16px;
    gap: 0px;
  }
}

.nft_content {
  width: 100%;
}

.buttonRow {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.notForSale {
  padding: 24px;
  border: 1px solid $pink2;
  border-radius: 8px;

  &Text {
    max-width: 400px;
    @include subheading24($basicWhite);
  }
}

.tooltipStyles {
  padding: 8px !important;
  max-width: 175px !important;
  background-color: #fff !important;
  opacity: 1 !important;
  @include highlight14($basicDark);
  color: $basicDark !important;
}

.importNftBtn {
  @media (max-width: $tabletSmall) {
    display: none !important;
  }
}
