@import '../../../assets/styles/mixins';

.SearchContainer {
  display: flex;
  align-items: center;
  border: 2px solid $basicWhite;
  padding: 14px 24px;
  border-radius: 48px;
  gap: 10px;

  .searchWrapper {
    width: 100%;
  }

  .SearchInput {
    border: none;
    border-radius: 48px;
    font-size: 20px;
    line-height: 32px;
    outline: unset;
    @include highlight20($grayscale);

    background-color: transparent;
    &::placeholder {
      color: $grayscale;
    }

    // &:focus {
    //   border: 2px solid #EE9784;
    //   color: #EE9784;
    // }

    // &:active {
    //   border: 2px solid #948C8C;
    //   color: #221E1E;
    // }
  }

  .SearchIcon {
    path {
      fill: #f9f9f9;
    }
  }
}
