@import '../../../assets/styles/mixins';

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 40px;
  border-radius: 48px;

  background: $purple;
  @include highlight16($basicWhite);
}

// .rare {
//   background: $rare;
//   color: $basicWhite;
// }
// .platinum {
//   background: $platinum;
//   color: $basicDark;
// }
// .gold {
//   background: $gold;
//   color: $basicDark;
// }
// .legendary {
//   background: $legendary;
//   color: $basicWhite;
// }
