@import '../../../assets/styles/global';
@import '../../../assets/styles/mixins';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-width: 0;
  padding: 12px 32px;
  border-radius: 10px;

  transition: 0.3s ease;

  &.medium {
    @include heading20($basicWhite);
  }

  &.small {
    padding: 12px 24px;
    @include heading16($basicWhite);
  }

  &.primary {
    &.contained {
      background: $gradientButtonV2;

      //TODO DESIGN
      &:hover {
        background: $purple;
        transition: 0.3s ease;
      }

      &:active {
        background: $purple;
        color: $basicDark;
      }

      &.disabled,
      &[disabled] {
        background: $buttonDisabled;
        color: $grayscale2;
      }
    }
    &.outlined {
      background: transparent;
      border: 2px solid #650f8b;
      // TODO - рамка с gradient не скругляется
      // border-image: $gradientButtonV2;
      // border-image-slice: 1;

      &:hover {
        transition: 0.3s ease;
        border: 2px solid $purple;
      }

      &:active {
        color: $purple;
        transition: 0.3s ease;
        border: 2px solid $purple;
      }

      &.disabled,
      &[disabled] {
        color: $buttonDisabled;
        border: 2px solid $buttonDisabled;
      }
    }
  }

  &.rounded {
    padding: 12px 24px;
    border-radius: 48px;
    background: $basicWhite;
    color: $basicDark;
    &:hover {
      color: $basicWhite;
      transition: 0.3s ease;
      background: $basicDark;
    }

    &:active {
      color: $basicWhite;
      transition: 0.3s ease;
      background: $grayscale2;
    }

    &.disabled,
    &[disabled] {
      border: 2px solid $grayscale;
      color: $grayscale;
      background: transparent;
    }
  }

  &[disabled] {
    cursor: default;
  }

  .Icon {
    padding-right: 8px;
    display: flex;
    align-items: center;
  }
}

.full {
  width: 100%;
}
