$primary: #50b078;
$maxAppWidth: 1200px;
$tablet: 992px;
$tabletSmall: 768px;
$mobile: 600px;

$basicWhite: #f9f9f9;
$basicDark: #161026;

$black: #000;
$white: #fff;

$grayscale: #d3d2d4;
$grayscale2: #989898;

$pink: rgba(179, 2, 227, 0.3);
$pink2: #ae00ff;
$purple: #8101eb;
$purple2: #650f8b;
$legendary: #cf6300;
$gold: #ffd600;
$rare: #0029ff;
$error: #f02b00;
$success: #26c000;
$platinum: #d8ca9d;

$gradientBackground: linear-gradient(
  90.1deg,
  #1c121e 0.76%,
  #510b70 29.79%,
  #2d0c60 62.61%,
  #1b121e 99.23%
);
$gradientButton: linear-gradient(
  90.1deg,
  #c400ff 0.76%,
  #ad00ff 37.99%,
  #bf00ff 65.92%,
  #8101eb 99.23%
);
$gradientButtonV2: linear-gradient(
  134.77deg,
  #a229ba 0%,
  #650f8b 45.32%,
  #40108a 99.54%
);

$buttonDisabled: #330e52;
$gradientButtonHover: #330e52;

$cardBackground: rgba(48, 12, 90, 0.5);
$cardBoxShadow: 0px 0px 24px rgba(60, 54, 54, 0.16);
$uploadCardBoxShadow: 0px 2px 8px rgba(34, 30, 30, 0.16);
