@import '../../../../src/assets/styles/mixins';
@import '../../../../src/assets/styles/variables.scss';

.tabs {
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 24px;
  }

  // @media (max-width: $mobile) {
  //   padding: 0 16px;
  // }
}

.tab {
  cursor: pointer;
  padding-bottom: 16px;
  width: fit-content;
  position: relative;
  transition: 0.4s;
  user-select: none;

  @include heading20($grayscale);

  @media (max-width: $mobile) {
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  &::after {
    content: '';
    width: 100%;
    height: 4px;
    background: $purple;
    border-radius: 4px;
    position: absolute;
    bottom: -4px;
    left: 0;
    opacity: 0;
    transition: 0.4s;
    @media (max-width: $mobile) {
      bottom: 0;
    }
  }

  &.disabled {
    cursor: default;
    color: #d7cdcd;
  }

  &.active {
    &:not(.disabled) {
      color: $basicWhite;
    }
    color: $basicWhite;
    transition: 0.4s;

    &::after {
      opacity: 1;
      transition: 0.4s;
    }
  }
}
