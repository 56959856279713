
.wrapper {
}

.container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.input {
  display: none;
}

.icon {
  display: block;
  width: 20px;
}

.label {
  font-family: OpenSans-SemiBold, sans-serif;
  color: #554F4F;
  flex: 1;
  display: block;
  cursor: pointer;
}
