$mobile: 743px;

.dropdown {
  margin-left: 30px;

  @media (max-width: $mobile) {
    margin-left: 16px;
  }
}

.dropdownOptions {
  right: 0;
}
.is_active {
  border-radius: 48px;
}
