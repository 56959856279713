@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.copy {
  position: relative;
  display: flex;
  align-items: center;
  color: #221e1e;
  margin-bottom: 16px;
  cursor: pointer;
  width: max-content;

  @include heading16($basicWhite);

  svg {
    margin-left: 8px;
  }
  svg:hover {
    fill: rgb(153, 153, 153) !important;
  }
  .copy__notification {
    position: absolute;
    top: -5px;
    right: -90px;
    padding: 7px 10px;
    @include heading16($basicWhite);
    background: $basicDark;
    border: 1px solid $purple2;
    box-shadow: $cardBoxShadow;
    border-radius: 10px;
  }
}
