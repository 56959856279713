.container {
  max-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
}

.loaderSize {
  width: 63px;
  height: 63px;
}

.link {
  text-decoration: none;
}
