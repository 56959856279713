.Button {
  &::after {
    content: 'Following';
  }

  &:not([disabled]) {
    &:hover {
      &::after {
        content: 'Unfollow';
      }
    }
  }
}
