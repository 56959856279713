@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.followsInfo {
  display: flex;
  gap: 4px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: $tabletSmall) {
    flex-direction: column;
  }
}
.followers {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  width: 50%;
  flex: 0 0 50%;

  @media (min-width: calc($tabletSmall - 1px)) {
    width: 100%;
    flex: 0 0 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.isCanClick {
  cursor: pointer;
}

.title {
  @include highlight16($grayscale);
}

.value {
  @include heading20($basicWhite);
}
