.container {
  width: 478px;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.steps__launch {
  display: flex;
  flex-direction: column;
  align-items: start;
}