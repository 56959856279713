@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.page {
  padding: 64px 0;
  @media (max-width: $tablet) {
    padding: 40px 0;
  }
}
.page__back_wrapper {
  margin-bottom: 24px;
}
.page__title {
  text-align: center;
  margin-bottom: 40px;
  @include heading32($basicWhite);
  @media (max-width: $tablet) {
    margin-bottom: 24px;
  }
}

.page__description {
  margin: -16px 40px 40px 40px;

  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #221e1e;
}
