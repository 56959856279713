.DarkBackground {
  background-color: #FFF1EE;
  width: 30vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LightBackground {
  background-color: #221E1E;
  width: 30vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
