@import '../../../assets/styles/mixins';

.container {
  margin-bottom: 40px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: $tablet) {
    margin-bottom: 32px;
  }
}

.title {
  @include subheading24($basicWhite);

  @media (max-width: $tablet) {
    @include heading16($basicWhite);
  }
}

.grid {
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media (max-width: $tablet) {
    flex-direction: column;
    justify-content: center;
  }
}
.left {
  @media (max-width: $tablet) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (max-width: $tablet) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.faq_link {
  @media (max-width: $tablet) {
    margin-bottom: 0;
  }
}
.agreement_block {
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column-reverse;
    padding: 16px 0;
    gap: 24px;
    margin-top: 24px;
  }
  .button {
    margin-left: 20px;
    margin-bottom: 24px;
    width: 216px;
    @media (max-width: $tablet) {
      width: 100%;
      margin: 0;
    }
  }

  .agreement {
    padding-left: 20px;
    width: 80%;
    @media (max-width: $tablet) {
      padding: 0;
      margin: 0;
      width: 100%;
    }
  }
}

.buttons {
  button {
    width: 216px;
    @media (max-width: $tablet) {
      width: 100%;
    }
  }
}

.loaderSize {
  width: 63px;
  height: 63px;
}

.cardWidth {
  width: 380px;
}

.usd {
  margin-top: 12px;
}

.bidErrorText {
  align-items: flex-start;
}
