$mobile: 743px;

.more_wrap {
  position: relative;
  .more__button {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
    box-shadow: 0 0 24px rgba(60, 54, 54, 0.16);
    border: 2px solid #8101eb;

    border-radius: 48px;
    cursor: pointer;

    transition: 0.3s ease all;
    &:hover {
      background: rgba(179, 2, 227, 0.3);
      transition: 0.3s ease all;
    }
  }
  .more__dropdown {
    position: absolute;
    top: 55px;
    right: 0;
    padding: 8px;
    gap: 8px;
    width: 117px;
    background: #161026;
    box-shadow: 0 2px 8px rgba(34, 30, 30, 0.16);
    border-radius: 4px;
    @media (max-width: $mobile) {
      left: 0;
    }
    .more__dropdown_item {
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #f9f9f9;
      cursor: pointer;

      path {
        fill: #f9f9f9;
        fill-opacity: 1;
      }
    }
  }
}
