@import '../../../assets/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  img {
    display: block;
    margin-right: 12px;
    max-width: 32px;
    max-height: 32px;
  }
  span {
    @include heading20($basicWhite);
  }
}
