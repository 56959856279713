.modal-wrapper {
	
}
.modal-wrapper > * {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(60, 54, 54, 0.8);
  backdrop-filter: blur(6px);
}