@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include heading40($basicWhite);

  @media (max-width: $tablet) {
    padding: 0 16px;
  }
}

.info__title {
  @include subheading24($basicWhite);
  text-transform: initial;
  @media (max-width: $tablet) {
    text-align: center;
  }
}

.info__text {
  margin-bottom: 30px;
  @include body20($basicWhite);
  text-transform: initial;
  @media (max-width: $tablet) {
    text-align: center;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #a34f3d;

    &:hover {
      text-decoration: underline;
    }
  }
}
