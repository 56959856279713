@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.header {
  width: 100%;
  background-color: $basicDark;
  display: flex;
  justify-content: center;
}

.header__link {
  text-decoration: none;
}

.header__content {
  max-width: $maxAppWidth;
  padding: 24px 40px;
  z-index: 88;

  @media (max-width: calc($tablet + 50px)) {
    padding: 8px 16px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header__logo {
  text-decoration: none;
}

.header__tabs {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $tablet) {
    display: none;
  }
}
