.loader__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 101;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
}

.loader__container_dark {
  background: linear-gradient(0deg, rgba(60, 54, 54, 0.64), rgba(60, 54, 54, 0.64));
}

.loader__pic {
  width: 50px;
  height: 50px;
}