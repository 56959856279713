@import '../../../assets/styles/mixins';

.link {
  display: flex;
  align-items: center;
  gap: 10px;

  text-decoration: none;

  @include highlight16($purple);
}
