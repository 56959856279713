@import '../../../../src/assets/styles/mixins';
@import '../../../../src/assets/styles/variables.scss';

.FollowsItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
}
.FollowsItem__user {
	display: flex;
	align-items: center;
	gap: 12px;
}
.FollowsItem__avatar {
	display: block;
	width: 48px;
	height: 48px;

	border-radius: 50%;
}
.FollowsItem__username {
	word-break: break-all;
	@include highlight14($basicWhite)
}
.FollowsItem__first_name {
	letter-spacing: 0.02em;
	word-break: break-all;
	@include highlight14($grayscale)
}