.wrapper{
  display: flex;
  justify-content: space-between;
  padding: 24px 40px;
}

.bottomButton{
  width: 216px;

  &[disabled] {
    cursor: default;
  }
}
