@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.upload {
  //max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(34, 30, 30, 0.16);
  transition: box-shadow 300ms ease;
  border-radius: 6px;
  user-select: none;
  position: relative;

  border-radius: 8px;
  box-shadow: $uploadCardBoxShadow;
  border: 2px solid #650f8b;
  // TODO - рамка с gradient не скругляется
  // border-image: $gradientButtonV2;
  // border-image-slice: 1;

  &:hover {
    box-shadow: 0 2px 8px rgba(34, 30, 30, 0.4);
  }
}

.error {
  position: absolute;
  z-index: 100;
  bottom: -24px;
  left: 0;
  color: #cd3b1b;
  font-size: 12px;
  padding: 0 10px;
}

.upload__label {
  flex: 1;
  color: #000;
  padding: 16px;
  user-select: none;
  cursor: pointer;

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.upload__input {
  display: none;
}

.upload__picture {
  max-width: 72px;
  max-height: 72px;

  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  & * {
    max-height: inherit;
    max-width: inherit;
    height: 72px;
    width: 72px;
    object-fit: cover;
  }
}

.upload__text {
  max-width: 225px;

  overflow: hidden;
  margin-left: 16px;
  @include heading16($grayscale);
  @media (max-width: $mobile) {
    max-width: 130px;
  }
}

.icon {
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.message {
  > span {
    max-width: 270px;
    > svg {
      width: 100%;
      max-width: max-content;
    }
  }
}

.upload__icon {
  margin-left: auto;
}

.MessageError {
  position: absolute;
  z-index: 100;
  bottom: -32px;
  left: 0;

  display: flex;
  align-items: center;
  gap: 9px;
}
.MessageErrorIcon {
  flex: 0 0 20px;
}
.MessageErrorText {
  font-size: 16px;
  line-height: 24px;
  font-family: OpenSans-Bold, sans-serif;
  color: #cd3b1b;
}
