.loader__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader__container_dark {
  background: linear-gradient(
    0deg,
    rgba(60, 54, 54, 0.64),
    rgba(60, 54, 54, 0.64)
  );
}

.loader__pic {
  width: inherit;
  height: inherit;
  max-width: 50px;
}
.loader__pic_dark {
  [class*='circle_1'] {
    stroke: rgba(255, 255, 255, 0.56);
  }
  [class*='circle_2'] {
    stroke: #fff;
  }
}
