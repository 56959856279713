@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.button__back {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;

  @include highlight20($grayscale);
}
