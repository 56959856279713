.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blocks {
  display: flex;
  gap: 40px;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {

}

.productCard {
  [class*=card__img] {
    width: 250px;
  }
}