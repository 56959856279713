@import './../../../assets/styles/mixins.scss';
@import './../../../assets/styles/variables.scss';

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 360px;
    position: relative;

    border: 2px solid $purple;
    box-shadow: $cardBoxShadow;
    background: $basicDark;
    border-radius: 10px;
    padding: 40px;
}
.modal__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    path {
      transition: 0.4s;
    }

    &:hover {
      path {
        fill: #221e1e;
        transition: 0.4s;
      }
    }
}
.modal__title {
    margin-bottom: 12px !important;
    @include highlight24($basicWhite);
}
.modal__text {
    text-align: center;
    margin-bottom: 32px;
    @include highlight16($basicWhite);
}
.modal__btn {
    width: 100%;
    & > button {
        width: inherit;
    }
}
