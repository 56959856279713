@import '../../../assets/styles/mixins';

.title {
  margin-bottom: 8px;

  @include highlight16($grayscale);
}

.profile {
  display: flex;
  align-items: center;
  padding: 4px 16px 4px 4px;
  gap: 16px;
  width: max-content;
  background-color: transparent;
  border: 2px solid $purple2;
  border-radius: 10px;

  color: #221e1e;

  @include heading16($basicWhite);

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
