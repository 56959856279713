@import '../../../assets/styles/mixins';

.paper {
  border: 1px solid $pink2;
  box-shadow: 0 0 24px rgb(60 54 54 / 16%);
  border-radius: 8px;
  max-width: 880px;
  background: #161026;
  padding: 40px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: $tablet) {
    padding: 32px 24px;
  }
}
