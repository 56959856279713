@import '../../../assets/styles/mixins';

.dropdown {
  margin-left: 30px;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 0;
  }

  @media (max-width: $tablet) {
    margin-left: 0;
  }
}

.dropdownOptions {
  right: 0;
  @media (max-width: $tablet) {
    right: -20%;
  }
}
.is_active {
  // border: 2px solid #ffa693;
  // border-radius: 48px;
}
