.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;
}

.progressBar {
  width: 480px;
  margin: 16px 0 24px;
}

.description {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6F6767;
}

.link {
  margin: 12px 0 75px ;
}

.progress {
  width: 100%;
  height: 8px;
  background-color: #FFF1EE;
  border-radius: 120px;
}

.bar {
  width: 0;
  height: 100%;
  background-color: #EE9784;
  border-radius: 120px;
}

.wrap {
  display: flex;
  align-items: center;
  text-decoration: none;

  .icon{
    margin-right: 10px;
  }

  span {
    color: #A34F3D;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}