@import './../../../assets/styles/variables.scss';
@import './../../../assets/styles/mixins.scss';

.Wrapper {
  position: relative;
}

.Button {
  background: $basicDark;
  border: 2px solid $purple2;
  box-shadow: $uploadCardBoxShadow;
  border-radius: 10px;
  padding: 4px 16px 4px 4px;

  &Secondary {
    background: transparent;
    border-color: $grayscale;
    padding: 12px 14px;
    border-radius: 48px;
  }

  @include highlight16($basicWhite);

  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;

  &.withImg {
    padding: 4px 24px 4px 4px;
  }

  .Image {
    border-radius: 50%;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
    display: block;
  }

  .ArrowDown {
    margin-left: 8px;
    transition: 0.2s;
    width: 24px;
    height: 24px;
    margin-right: 0;

    path {
      fill: $basicWhite;
    }

    &.rotate {
      transform: rotate(-180deg);
      transition: 0.2s;
    }
  }
}

.Dropdown {
  position: absolute;
  top: 55px;
  visibility: hidden;
  background: $basicDark;
  border: 2px solid $purple2;
  box-shadow: $uploadCardBoxShadow;
  border-radius: 4px;
  padding: 8px;
  transition: 0.2s;
  opacity: 0;
  animation: ease-in;
  z-index: 20;
  max-height: 300px;
  overflow: auto;

  &.open {
    visibility: visible;
    transition: 0.2s;
    opacity: 1;
    animation: ease-out;
  }

  .Option {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 8px;

    border-radius: 4px;
    white-space: nowrap;

    cursor: pointer;
    @include heading16($basicWhite);
    transition: 0.2s;

    &:hover,
    &Checked {
      background-color: $basicWhite;
      color: $basicDark;
      transition: 0.2s;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .Icon {
      width: 24px;
      height: 24px;
      margin: 0;
      margin-right: 8px;
    }

    /* Customize the label (the container) */
    .checkboxContainer {
      height: 22px;
      width: 22px;
      margin-right: 11px;
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .checkboxContainer input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      border: 2px solid #3c3636;
      border-radius: 5px;
    }

    /* On mouse-over, add a grey background color */
    .checkboxContainer:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .checkboxContainer input:checked ~ .checkmark {
      background-color: #ee9784;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .checkboxContainer input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkboxContainer .checkmark:after {
      left: 5px;
      top: 0px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.active {
  display: flex;
  justify-content: space-between;
}
