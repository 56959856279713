@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.footer {
  background-color: #221e1e;
  display: flex;
  justify-content: center;
}

.footer__content {
  max-width: $maxAppWidth;
  display: flex;
  padding: 12px 40px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: inherit;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;

  @media (max-width: $tablet) {
    padding: 32px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > a svg {
    width: 251px;
    height: 48px;
    margin-right: 50px;

    @media (max-width: 780px) {
      max-width: 210px;
      max-height: 40px;
      margin: 0;
    }
  }
}

.footer__copy {
  @include heading20($basicWhite);
  @media (max-width: $tablet) {
    display: none;
  }
}

.footer__logo {
  text-decoration: none;
  display: none;
  @media (max-width: $tablet) {
    display: block;
    margin-bottom: 56px;
  }
}

.footer__tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: $tablet) {
    flex-direction: column;
    > * {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.footer__logos {
  display: flex;
  width: fit-content;
  margin-left: 34px;

  @media (max-width: 645px) {
    margin: 34px 0 0 0;
  }

  svg {
    width: 20px;
    height: 20px;
    margin: 0;

    path {
      fill: white;
      opacity: 0.75;
    }
  }
}

.footer__icon {
  margin-right: 20px;
}

.tabLabel {
  cursor: pointer;
  text-decoration: none;
  transition: 0.4s;
  background: transparent;
  border-radius: 10px;
  padding: 8px 12px;
  @include heading16($basicWhite);
  align-items: center;
  display: flex;
  > svg {
    margin-right: 4px;
  }
  &:hover {
    transition: 0.4s;
    background: $pink;
  }
  &.active {
    color: $purple;
    cursor: default;
  }
}
