@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  // height: 100%;
  min-height: calc(100vh - 164px);
  // min-height: calc(100vh - 144px);
  padding: 0 40px;
  background: linear-gradient(
    90.1deg,
    #1c121e 0.76%,
    #510b70 29.79%,
    #2d0c60 62.61%,
    #1b121e 99.23%
  );

  @media (max-width: $tablet) {
    padding: 0 20px;
  }
  @media (max-width: $tablet) {
    // min-height: 100vh;
    padding: 0 16px;
  }
}

.app {
  max-width: $maxAppWidth;
  height: inherit;
  @include fit-max-width();

  @media (max-width: 1200px) {
    background-position: 0 110px;
  }

  @media (max-width: 1000px) {
    // background-position: 0 180px;
  }

  @media (max-width: 950px) {
    background-position: 0 40px;
  }
}
