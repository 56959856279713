$mobile: 743px;

.nft__modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgb(20 18 18 / 75%);
  backdrop-filter: blur(6px);
  z-index: 99;

  @media (max-width: $mobile) {
    padding: 16px;
  }

  .nfr__modal_wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba(60, 54, 54, 0.8);
  }
}
