.formWrapper {
  margin: -20px -20px -20px 20px;
}

.input {
  width: 100% !important;
}

.input_wrapper {
  padding: 20px;
}

.textarea {
  &:focus {
    border: 2px solid #221E1E !important;
    color: #221E1E !important;
  }
}

.selectWrap {
  padding: 0 20px;

  .select {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 2px solid #948C8C !important;
    margin-bottom: 4px !important;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 20px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .dropdown {
    right: 0 !important;
  }
}

.textAreaWrap {
  padding: 20px;
}

.description {
  padding: 0 20px 20px 20px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6F6767;
}