.GenreWrapper {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  cursor: pointer;

  .Genre {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    width: inherit;
    height: inherit;
    backdrop-filter: blur(4px);
    transition: 0.4s;
    background: rgba(34, 30, 30, 0.4);

    &:hover {
      background: rgba(34, 30, 30, 0.5);
      transition: 0.4s;
    }
  }


  .Name {
    font-size: 40px;
    line-height: 56px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: CormorantSC-Bold;
  }
}