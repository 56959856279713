@import '../../../../src/assets/styles/mixins';
@import '../../../../src/assets/styles/variables.scss';

.FollowsModal {
}
.FollowsModal__form {
  position: relative;

  width: 100%;
  max-width: 640px;
  box-sizing: border-box;

  background: $basicDark;
  border: 1px solid $purple2;
  box-shadow: $cardBoxShadow;
  border-radius: 10px;
  @media (max-width: $tablet) {
    max-width: 90vw;
    max-height: 90vh;
  }
}
.FollowsModal__close {
  position: absolute;
  top: 16px;
  right: 16px;

  cursor: pointer;
}
.FollowsModal__header {
  padding: 24px 24px 0 24px;
}
.FollowsModal__body {
  padding: 24px;
  max-height: 70vh;
  overflow-y: auto;
  @media (max-width: $mobile) {
padding: 16px;
  }
}
.FollowsModal__body > * > *:not(:last-child) {
  margin-bottom: 24px;
}
.FollowsModal__divider {
  width: 100%;
  height: 1px;
  @media (max-width: $mobile) {
    margin-top: -9px;
  }

  background-color: #ebeaea;
}
