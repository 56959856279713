@import '../../../assets/styles/mixins';

.panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  margin-bottom: 24px;

  @media (max-width: $tablet) {
    margin: 0 auto;
    padding: 0 16px;
  }
}

.panel__control {
  display: flex;

  @media (max-width: $tablet) {
    width: 100%;
    justify-content: space-around;
  }
}
