$mobile: 743px;

.mb30 {
  margin-bottom: 30px;
}

.Row {
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: $mobile) {
    flex-direction: column;
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  .button_wrap {
    width: 100%;
    @media (max-width: $mobile) {
      margin-bottom: 24px;
      & > button {
        width: 100% ;
      }
    }
  }
  .Column {
    flex: 1 1 50%;
    &:first-child {
      margin-right: 40px;
    }

    .Title {
      font-family: OpenSans-Bold, sans-serif;
      font-size: 24px;
      line-height: 40px;
      color: #221E1E;
      //margin-bottom: 12px;
    }

    .Description {
      font-family: OpenSans-Medium, sans-serif;
      color: #221E1E;
      font-size: 20px;
      line-height: 32px;
    }

    .FieldWrap {
      div {
        padding: 0;
      }
    }
  }
}

.upload__input {
  opacity: 0 !important;
  position: absolute;
  z-index: -1;
}
.title__verify {
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}
.verify__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: OpenSans-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #221E1E;
  box-shadow: 0 2px 8px rgba(34, 30, 30, 0.16);
  border-radius: 48px;
  padding: 12px 0;
  margin-bottom: 24px;
  cursor: pointer;
  text-decoration: none;
}
.verify__icon {

  margin-right: 10px;
  
  path {
    fill:#000;
    fill-opacity: 1;
  }
}
.verify__verifyIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.resultButton {
  width: 100%;
}
.buttons {
  margin-top: 60px;
}
