@import '../../../assets/styles/mixins';

.footer {
  //hack for border outer container
  margin: 0 -30vw -64px;
  border-top: 1px solid #f9f9f9;

  @media (max-width: $tablet) {
    border: none;
    height: auto;
    padding: 0;
    margin: 0;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  max-width: $maxAppWidth;
  margin: 0 auto;
  width: 100%;

  padding: 24px 40px;

  @media (max-width: $tablet) {
    padding: 0;
  }
}

.listBtn {
  margin-left: auto;
}
