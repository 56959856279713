.gallery {
  max-width: 1200px;
  width: 100%;
}

.row {
  display: flex;
  height: 240px;
}
.row:not(:last-child) {
  margin-bottom: 24px;
}
.row > div {
}
.row_3 > div {
width: calc(100% / 3);
}
.row_2 > div {
width: calc(100% / 2);
}
.row_1 > div {
width: 100%;
}
#row__item_40 {
width: 40%;
}
#row__item_60 {
width: 60%;
}

.row__item {
  border-radius: 8px;
  overflow: hidden;
}
.row__item:not(:last-child) {
  margin-right: 24px;
}
