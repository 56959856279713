@import '../../../assets/styles/mixins';

.container {
  display: flex;
  padding: 24px;
  background: rgba(48, 12, 90, 0.5);
  box-shadow: 0px 0px 24px rgba(60, 54, 54, 0.16);
  border-radius: 8px;
  border: 1px solid hsl(286, 100%, 50%);

  &Big {
    background: rgba(179, 2, 227, 0.3);
  }

  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
  }

  .image {
    width: 268px;
    height: 268px;
    object-fit: cover;
    margin-right: 32px;

    &Big {
      width: 392px;
      height: 392px;
    }

    @media (max-width: $tablet) {
      width: 100%;
      height: auto;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    border-right: 1px solid #ebeaea;
    flex: 1;

    @media (max-width: $tablet) {
      margin-top: 24px;
      border-right: 0;
      border-bottom: 1px solid #ebeaea;
    }

    .name {
      margin-bottom: 4px;

      @include subheading24($basicWhite);
    }

    .author {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      @include body16($basicWhite);

      .title {
        margin-right: 4px;
        @include heading14($grayscale);
      }
    }

    .price_and_time {
      display: flex;
      justify-content: space-between;

      @media (max-width: $tablet) {
        flex-direction: column;
        gap: 32px;
        align-items: start;
      }

      .price {
        width: 100%;
        .title {
          text-transform: uppercase;
          @include heading14($grayscale);
        }

        .value {
          font-size: 24px;
          line-height: 40px;
        }
      }

      .time {
        width: 100%;
        .title {
          margin-bottom: 4px;
          text-align: right;
          text-transform: uppercase;
          @include heading14($grayscale);

          @media (max-width: $tablet) {
            text-align: left;
          }
        }

        .value {
          text-align: right;

          @media (max-width: $tablet) {
            text-align: left;
            margin-bottom: 24px;
          }
        }

        .numbers {
          font-size: 24px;
          line-height: 40px;
          @include subheading24($basicWhite);
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 315px;
    margin-left: 32px;

    &FlexStart {
      justify-content: flex-start;
    }

    @media (max-width: $tablet) {
      margin-left: 0;
      min-width: 100%;
      margin-top: 24px;
    }

    button {
      width: 100%;
    }

    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 4px;

      @media (max-width: $tablet) {
        margin-bottom: 24px;
      }

      &.win {
        flex-direction: column;
      }

      .title {
        margin-bottom: 4px;
        @include subheading24($basicWhite);
      }

      .description {
        max-width: 315px;
        @include body16($basicWhite);
      }

      .price {
        width: 100%;

        .priceHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 6px;
        }

        .title {
          margin-bottom: 0;
          color: #d3d2d4;
          font-size: 14px;
          line-height: 24px;
          text-transform: uppercase;
        }

        .value {
          font-size: 24px;
          line-height: 40px;
        }
      }

      .status {
        display: flex;
        align-items: flex-start;
        white-space: nowrap;
        @include heading16();

        &Green {
          color: #36a31a;
        }

        &Red {
          color: #cd3b1b;
        }

        svg {
          margin-right: 8px;
        }
      }
    }
  }
}

.bnbPrice {
  @include subheading24($basicWhite);
}
