@import '../../../../src/assets/styles/mixins';
@import '../../../../src/assets/styles/variables.scss';

.container {
  padding-top: 248px;
  width: 100%;

  @media (max-width: $tabletSmall) {
    margin: 0;
    padding: 0;
  }

  .content__body_header {
    display: flex;
    justify-content: space-between;
  }
}

.tabs_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d3d2d4;
  @media (max-width: $tablet) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    > *:last-child {
      grid-row: 1;
    }
  }
  @media (max-width: $tabletSmall) {
    display: block;
    border: 0;
  }
  .share {
    margin-top: -16px;
    @media (max-width: $tablet) {
      margin-top: 0;
    }
    @media (max-width: $tabletSmall) {
      display: none;
    }
  }
}

.list {
}

.card {
  width: 100%;
  > div {
    width: 100%;
    margin: 0 auto;
  }
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 14px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-gap: 24px;

  @media (max-width: calc($maxAppWidth + 100px)) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $tablet) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $tabletSmall) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
  }
  @media (max-width: $mobile) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.products::after {
  content: '';
  flex: auto;
}

.empty {
  padding-top: 50px;
}
