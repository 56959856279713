@import '../../../assets/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: $tablet) {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }
}

.text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;

  svg {
    margin-right: 10px;
  }

  span {
    @include highlight16($purple);
  }
}
