@import '../../../assets/styles/mixins';

.list {
  padding: 0 40px;

  @media (max-width: 1370px) {
    padding-right: 0;
  }
  @media (max-width: 1250px) {
    padding-left: 0;
  }
  @media (max-width: $tablet) {
    padding: 0 16px;
  }

  .text {
    display: flex;
    gap: 16px;
    margin-bottom: 32px;

    @media (max-width: $tablet) {
      width: 100%;
      justify-content: space-between;
    }

    .title {
      @include subheading24($basicWhite);
    }

    .path {
      display: flex;
      align-items: center;
      text-decoration: none;

      gap: 10px;

      @include heading20($purple);

      path {
        fill: $purple;
      }
    }
  }
}
