@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.TextareaContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  .Label {
    margin-bottom: 8px;
    @include heading16($grayscale);
  }

  .Textarea {
    height: 280px;
    resize: none;
    background: transparent;
    padding: 12px;
    outline: unset;
    @include body20($grayscale);

    &::placeholder {
      @include body20($grayscale);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    border-radius: 4px;
    margin-bottom: 12px;
    border: 2px solid #650f8b;
    // TODO - рамка с gradient не скругляется
    // border-image: $gradientButtonV2;
    // border-image-slice: 1;
    transition: ease 0.3s;

    &:focus {
      border: 2px solid $purple;
    }

    &:active {
      border: 2px solid $purple;
      color: $basicWhite;
    }

    &.error {
      border: 2px solid $error;
      color: $basicWhite;
      transition: ease 0.3s;
    }
  }

  .ErrorMessage {
  }

  .Message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .maxlength {
      @include heading16($grayscale);
    }
  }
}
