@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.container {
  background: $cardBackground;
  box-shadow: $cardBoxShadow;
  border: 1px solid $purple2;
  // TODO - рамка с градиентом не скругляется
  // border-image-source: linear-gradient(
  //   90.1deg,
  //   #c400ff 0.76%,
  //   #ad00ff 37.99%,
  //   #bf00ff 65.92%,
  //   #8101eb 99.23%
  // );

  border-radius: 8px;

  transition: all 300ms ease;
  overflow: hidden;

  a {
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0 0 24px rgba(60, 54, 54, 0.5);
  }

  .card {
    padding: 16px;

    display: flex;
    flex-direction: column;

    .image {
      width: 100%;
      height: 180px;
      margin-bottom: 12px;

      img {
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
        display: block;
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
        border-radius: 8px;
      }
    }

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      margin-bottom: 4px;
      @include heading20($basicWhite);
    }

    .nickName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      margin-bottom: 12px;
      @include heading16($grayscale);
    }

    .description {
      word-break: break-all;
      height: 120px;
      overflow: hidden;

      // Добавляет многоточие, если текст длинный
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      @include body16($basicWhite);
    }
  }

  .actions {
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;

    .followers {
      .title {
        @include highlight16($grayscale);
      }

      .value {
        @include heading20($basicWhite);
      }
    }
  }
}
