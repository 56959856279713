@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.report__modal {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // z-index: 99;
  // position: fixed;
  // left: 0;
  // top: 0;
  // width: 100vw;
  // height: 100vh;
  // background: rgba(60, 54, 54, 0.8);
  // backdrop-filter: blur(6px);
  .form__info_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    text-align: center;
  }
  .report_modal_form {
    background: $basicDark;
    border: 1px solid $purple2;
    box-shadow: $cardBoxShadow;
    border-radius: 10px;

    position: relative;
    max-width: 800px;
    width: 100%;
    padding: 40px;
    gap: 32px;
    @media (max-width: $mobile) {
      padding: 16px;
      max-width: 95vw;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .modal__close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
    .close__icon {
      path {
        fill: $basicWhite;
      }
    }
    .form__info {
      margin-bottom: 24px;
      .form__title {
        margin-bottom: 12px;
        @include highlight24($basicWhite);
      }
      .form__description {
        margin-bottom: 12px;
        @include highlight16($basicWhite);
        & > a {
          color: $purple;
          text-decoration: none;
        }
      }
    }
    .form__controls {
      display: flex;
      flex-direction: column;
      gap: 12px;
      & > button {
        width: 120px;
        margin: 0 auto;
      }
      & > label {
        display: flex;
        flex-direction: column;
        gap: 12px;

        margin-bottom: 8px;
        @include heading16($grayscale);
        .textarea__count {
          display: flex;
          justify-content: end;
          @include heading16($grayscale);
        }
        & > input,
        textarea {
          border-radius: 4px;
          border: none;
          background: transparent;
          padding: 12px;
          outline: unset;
          @include body20($grayscale);

          &::placeholder {
            @include body20($grayscale);
          }
          border: 2px solid #650f8b;
          // TODO - рамка с gradient не скругляется
          // border-image: $gradientButtonV2;
          // border-image-slice: 1;
          transition: ease 0.3s;

          &:focus {
            border: 2px solid $purple;
          }

          &:active {
            border: 2px solid $purple;
            color: $basicWhite;
          }

          &.error {
            border: 2px solid $error;
            color: $basicWhite;
            transition: ease 0.3s;
          }
        }
        & > textarea {
          resize: none;
          min-height: 160px;
        }
      }
    }
  }
}
