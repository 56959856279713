.container {
  display: flex;
  width: 478px;
  gap: 24px;
  flex-direction: column;
}

.info__block {
  display: flex;
  flex-direction: column;
  align-items: start;

  .info__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 8px;
    color: #221E1E;
  }

  .info__input {
    width: 100%;

    & > div {
      padding: 0;
    }
  }

  .info__description {
    gap: 10px;
    display: flex;

    & > svg {
      min-width: 20px;
      min-height: 20px;
    }

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    color: #6F6767;
  }

  .profile__redirect {
    display: flex;
    align-items: center;
    gap: 11px;
    color: #A34F3D;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
}
