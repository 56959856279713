.page__search {
  margin-bottom: 36px;

  & > div {
    width: 100%;

    input {
      width: 100%;
    }
  }
}
