@import '../../../assets/styles/mixins';

.separator {
  height: 100%;
  background-color: #161026;
  // border-bottom: 2px solid #650f8b;
  // border-top: 2px solid #650f8b;
}

.label {
  margin-bottom: 8px;
  @include heading16($grayscale);
}

.wrap {
  display: flex;
  align-items: center;
  padding: 12px;
  border: 2px solid #650f8b;
  border-radius: 4px;
  transition: ease 0.3s;
}

.input {
  border: none;
  background-color: transparent;
  outline: unset;

  @include body20($grayscale);

  &::placeholder {
    @include body20($grayscale);
  }

  &Sm {
    min-width: 29px;
    max-width: 29px;
  }

  &Md {
    min-width: 55px;
    max-width: 55px;
  }

  // &:focus {
  //   border: 2px solid $purple;
  // }

  // &:active {
  //   border: 2px solid $purple;
  //   color: $basicWhite;
  // }

  &.error {
    border: 2px solid $error;
    color: $basicWhite;
    transition: ease 0.3s;
  }
}
