@import '../../../assets/styles/mixins';

.nft__about {
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0px;
  }

  &BigMargin {
    margin-bottom: 32px;
  }

  &FlexRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Wrapper {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #ebeaea;
  }

  &Title {
    @include heading14($grayscale);
  }

  &Text {
    @include body16($basicWhite);
  }
}

.description {
  margin-bottom: 24px;

  &Title {
    margin-bottom: 12px;
    font-size: 14px;
    text-transform: uppercase;
    @include heading14($grayscale);
  }

  &Text {
    @include body16($basicWhite);
  }
}

.nft__info_wrap {
  width: 100%;

  .nft__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    @media (max-width: $tablet) {
      flex-direction: column;
      gap: 24px;
    }
    .nft__author .nft__genre_block {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .nft__section_block {
      .nft__name {
        @include subheading32($basicWhite);
      }
      .nft__artist {
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        .nft__artist_img {
          width: 32px;
          height: 32px;
          border-radius: 48px;
        }
      }
    }
    .nft__section_block_row {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .nft__genre_type {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #948c8c;
      border-radius: 48px;
      padding: 8px 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .nft__section_title {
    line-height: 24px;
    font-weight: 700;
    font-size: 14px;
    color: #6f6767;
  }

  .nft__action {
    width: 100%;
    margin-top: 24px;
    & > button {
      width: 100%;
    }
  }
}
