@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.header__burger {
  display: none;
  cursor: pointer;

  .header__burger_action_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  @media (max-width: $tablet) {
    display: block;

    .header__burger_menu {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      bottom: 0;
      left: 0;
      background-color: $basicDark;
      width: 100vw;
      height: calc(100% - 45px);
      z-index: 2;
      transition: all 0.5s ease;
      padding: 24px;

      .get__button {
        padding: 8px 12px;
      }

      .header__burger_tabs {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
          display: flex;
          justify-content: center;
        }
      }

      .header__burger_submenu {
        position: fixed;
        background-color: $basicDark;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 2;
        padding: 24px 16px;
        color: #ffffff;

        .header__burger_submenu_item {
          display: flex;
          align-items: center;
          font-size: 16px;
          gap: 10px;
          font-weight: 600;
          line-height: 24px;
          padding: 8px;
          margin-bottom: 16px;
        }

        .create__nft_warning {
          display: flex;
          background: #fbbfb129;
          padding: 12px;
          gap: 8px;
          border-radius: 8px;
          line-height: 24px;
          font-weight: 600;
          font-size: 14px;

          & > svg {
            margin-top: 1px;
          }
        }

        .back {
          padding: 8px 0;
          gap: 5px;

          @include highlight16($grayscale);
        }
      }

      .header__burger_connect {
        margin: 24px auto;
      }
    }
  }
  .header__burger_close {
    transform: translateX(100%);
  }
  .header__burger_open {
    transform: translateX(0%);
  }
}

.header__link {
  text-decoration: none;
}
