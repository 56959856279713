@import 'variables';

@mixin flex-col($gap-x: 0, $gap-y: 0, $width: auto) {
  width: $width;
  padding: calc(#{$gap-y} / 2) calc(#{$gap-x} / 2);
}
@mixin fit-max-width() {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}

// Fonts
@mixin heading64($color: #000000) {
  font-family: 'Heebo', sans-serif;
  font-weight: 700;
  font-size: 64px;
  line-height: 88px;
  text-transform: uppercase;
  color: $color;
}

@mixin heading40($color: #000000) {
  font-family: 'Heebo', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  text-transform: uppercase;
  color: $color;
}

@mixin heading32($color: #000000) {
  font-family: 'Heebo', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-transform: uppercase;
  color: $color;
}

@mixin heading20($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: $color;
}

@mixin heading16($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $color;
}

@mixin heading14($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $color;
}

@mixin subheading32($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: $color;
}

@mixin subheading24($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: $color;
}

@mixin highlight24($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: $color;
}

@mixin highlight20($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: $color;
}

@mixin highlight16($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $color;
}

@mixin highlight14($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: $color;
}

@mixin body20($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: $color;
}

@mixin body16($color: #000000) {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $color;
}
