@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.cover {
  position: absolute;
  left: 0;
  height: 160px;
  width: 100%;
  background-color: $gradientBackground;
  object-fit: cover;
  z-index: 1;

  
  @media (max-width: $tabletSmall) {
    position: static;
  }
}

.cover_img {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 0;
  pointer-events: none;

  @media (max-width: $tabletSmall) {
    display: none;
  }
}