.minting_steps_container {
  width: max-content;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.step {
  background: rgba(179, 2, 227, 0.3);
  border-radius: 8px;
  width: 40px;
  height: 8px;

  &.active {
    background: #8101eb;
  }
}
