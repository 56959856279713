@import './../../../assets/styles/mixins.scss';
@import './../../../assets/styles/variables.scss';

.ConnectModal {
  z-index: 89;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(60, 54, 54, 0.8);
  backdrop-filter: blur(6px);

  .Wrapper {
    border: 2px solid $purple;
    box-shadow: $cardBoxShadow;
    background: $basicDark;
    border-radius: 10px;
    padding: 40px;

    overflow: hidden;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    user-select: none;
    height: auto !important;

    max-height: 95vh;
    max-width: 95vw;
    width: max-content;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .CloseIcon {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;

      path {
        transition: 0.4s;
      }

      &:hover {
        path {
          fill: #221e1e;
          transition: 0.4s;
        }
      }
    }

    .Title {
      @include highlight24($basicWhite);

      text-align: center;
      margin-bottom: 12px;
    }

    .Description {
      text-align: center;
      max-width: 280px;
      margin-bottom: 32px;

      @include highlight16($basicWhite);
      a {
        color: $purple;
      }
    }

    .Wallets {
      .Wallet {
        @include highlight20($basicWhite);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 12px 16px;
        margin-bottom: 16px;
        transition: ease 0.4s;
        border-radius: 10px;
        border: 1px solid $purple;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background-color: $basicWhite;
          transition: ease 0.4s;
          color: $basicDark;
        }
      }
    }
  }
}
