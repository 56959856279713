@import '../../../assets/styles/mixins';

.col {
  width: 25%;

  max-width: 276px;

  @include flex-col(24px, 24px, 25%);

  @media (max-width: 1260px) {
    width: calc(100% / 3);
  }

  @media (max-width: 970px) {
    width: 50%;
  }

  @media (max-width: 620px) {
    width: 100%;
  }
}
