@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.container {
  padding: 16px;
  border-radius: 8px;
  box-shadow: $cardBoxShadow;
  background: $cardBackground;
  border: 2px solid $purple2;

  @media (max-width: $tabletSmall) {
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    background-color: transparent !important;
    border: 0;
    padding: 0;
  }
}

.image__wrapper {
  overflow: hidden;
  margin-bottom: 24px;
}

.card {
  border-radius: 8px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.img_plug {
  // border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.name {
  margin-bottom: 4px;
  @include subheading24($basicWhite);
}

.nick_name {
  @include highlight16($grayscale);
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;

  border-bottom: 1px solid $grayscale;
}

.status_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.status {
  display: flex;
  align-items: center;
  gap: 4px;
}

.become_artist {
  display: flex;
  padding: 0 16px;

  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #221e1e;

  border: none;
  background: #ebeaea;
  box-shadow: 0px 2px 8px rgba(34, 30, 30, 0.16);
  border-radius: 48px;

  transition: all 0.3s;
}
.become_artist:hover {
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(34, 30, 30, 0.16);

  transition: all 0.3s;
}

.followers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  align-items: center;

  .title {
    color: #6f6767;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  .value {
    color: #3c3636;
    font-size: 20px;
    line-height: 32px;
    font-family: OpenSans-Bold, sans-serif;
  }
}

.card__mobile {
  position: relative;
  color: #221e1e;

  .ellipsis {
    position: absolute;
    top: 30px;
    right: 80px;
  }

  .share {
    position: absolute;
    top: 30px;
    right: 0;
  }

  .card__img {
    box-shadow: 0 0 24px rgba(60, 54, 54, 0.16);
    background-color: #fff;
    border-radius: 8px;
    width: 104px;
    height: 104px;
    padding: 5px;
    margin-top: 25px;

    & > img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: $tabletSmall) {
      margin-bottom: 24px;
    }
  }

  .profile_activity {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    margin-bottom: 24px;
    border-bottom: 1px solid #ebeaea;
    border-top: 1px solid #ebeaea;

    .followers {
      display: flex;
      flex-direction: column;
      margin-bottom: 4px;
      align-items: center;

      .title {
        color: #6f6767;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .value {
        color: #3c3636;
        font-size: 20px;
        line-height: 32px;
        font-family: OpenSans-Bold, sans-serif;
        width: 100%;
        text-align: left;
      }
    }
  }
}

.actions {
  padding: 0 0 24px;
  border-bottom: 1px solid #ebeaea;
  //background: #F9F9F9;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  button {
    width: 100%;
  }
}

.bio {
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #ebeaea;
}
.bio_mobile {
  margin-bottom: 24px;
}
.title {
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 12px;
  @include heading14($grayscale);
}

.description {
  @include body16($basicWhite);
}

.bio_mobile {
  margin-bottom: 24px;
}
.bio_mobile .description {
  max-width: none;
}

.mobile_action_wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}
.card__mobile .mobile_action_wrapper > button {
  box-sizing: border-box;
  width: 100%;
}

.links {
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #ebeaea;

  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 12px;

    svg {
      margin-right: 8px;
    }

    @include heading16($basicWhite);
  }

  .link__icon {
    width: 20px;
    height: 20px;

    path {
      fill-opacity: 1;
    }
  }

  .link_hidden {
    display: none;
  }
}

.joined {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .date {
    @include body16($basicWhite);
  }
}

.joined {
  .title {
    margin-bottom: 0;
  }
}

.card__mobile .joined {
  padding: 0;
}
.links_mobile {
  margin-top: 24px;
}
.links_mobile_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  svg {
    width: 20px;
    height: 20px;
  }

  // path {
  //   fill: #000;
  //   fill-opacity: 1;
  // }
}
