@import '../../../assets/styles/mixins';
.text {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  span {
    @include highlight16($basicWhite);
  }

  & > svg {
    min-width: 20px;
    min-height: 20px;
  }
}

.error {
  color: red;
}
