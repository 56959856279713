@import '../../../assets/styles/mixins';

.page__list {
  min-height: 50px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  grid-gap: 24px;

  @media (max-width: $maxAppWidth) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $mobile) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.item {
  max-width: 276px;
  width: 100%;

  @media (max-width: $maxAppWidth) {
    max-width: 350px;
  }

  @media (max-width: $tablet) {
    max-width: 350px;
  }
}
