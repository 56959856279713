@import './global.scss';
@import './variables.scss';

strong {
  font-weight: bold;
}

html,
body,
#root,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;

  strong {
    font-weight: bold;
  }
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

* {
  box-sizing: border-box;
  font-family: Inter-Regular, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: normal;
}

html {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  color: #f9f9f9;
  line-height: normal;
}

div {
  box-sizing: border-box;
}

iframe {
  display: none !important;
}

//pagination
.pagination-item {
  align-items: center;
  border-radius: 50%;
  color: $grayscale;
  cursor: pointer;
  display: flex;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  padding: 7px;
  margin: 3px;
  & button {
    padding: 10px 12px !important;
  }
}

.disabled-page {
  color: $grayscale2;
}

.active {
  border: 2px solid $purple2;
  width: 40px;
  height: 40px;
  margin: 0;
}
.next {
  font-size: 4px;
  height: 60px;
  position: absolute;
  right: 0;
}

.pagination {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  padding-inline-start: 0px;
}

.pagination-page {
  font-weight: 700;
}

.previous {
  font-size: 4px;
  height: 60px;
  left: 0;
  position: absolute;
}
