@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.share__wrap {
  position: relative;
  .share__button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    gap: 8px;
    border: none;
    box-shadow: 0 0 24px rgba(60, 54, 54, 0.16);
    border-radius: 48px;
    padding: 12px 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #f9f9f9;
    cursor: pointer;
    border: 2px solid $purple2;
    background: transparent;
    border-radius: 10px;


    transition: 0.3s ease all;
    &:hover {
      border: 2px solid $purple;
      transition: 0.3s ease all;
    }
  }
  .share__dropdown {
    position: absolute;
    top: 55px;
    right: 0;
    padding: 8px;
    gap: 8px;
    width: 143px;
    background: $basicDark;
    box-shadow: $uploadCardBoxShadow;
    border-radius: 10px;
    border: 2px solid $purple2;

    .share__dropdown_item {
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 8px;
      cursor: pointer;

      @include heading16($basicWhite);

      svg {
        max-width: 20px;
      }

      path {
        fill: #f9f9f9;
        fill-opacity: 1;
      }
    }
  }
}
