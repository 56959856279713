@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.container {
  padding: 159px 0 0 0;
  height: 100%;
  display: flex;
  max-width: 100%;
  width: 100%;

  @media (max-width: $tabletSmall) {
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    padding-top: 16px;
  }
}

.profileCard__card {
  margin-top: -96px;
  position: relative;
  z-index: 1;
  background-color: #fff;
  min-width: 250px;
  height: fit-content;
}
