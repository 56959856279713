@import '../../../assets/styles/mixins';

.nft__img_wrap {
  position: relative;
  max-width: 580px;
  width: 100%;
  padding: 24px;

  background-color: rgba(48, 12, 90, 0.5);
  box-shadow: 0px 0px 24px rgba(60, 54, 54, 0.16);
  border-radius: 8px;
  border: 1px solid $pink2;

  @media (max-width: $tablet) {
    margin-right: 0;
    margin-bottom: 24px;
  }

  .nft__img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .nft__img_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 32px;
    right: 32px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(34, 30, 30, 0.16);
    border-radius: 48px;
    cursor: pointer;
  }
}
