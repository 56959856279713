@import '../../../assets/styles/mixins';

.container {
  margin-bottom: 40px;
}

.grid {
  display: flex;
  gap: 40px;

  @media (max-width: $tablet) {
    flex-direction: column;
    justify-content: center;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: $tablet) {
    margin-bottom: 32px;
  }
}

.left {
  @media (max-width: $tablet) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (max-width: $tablet) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.title {
  @include subheading24($basicWhite);

  @media (max-width: $tablet) {
    @include heading16($basicWhite);
  }
}
.usd {
  margin-top: 12px;
}

.button {
  width: 216px;
}

.loaderSize {
  width: 63px;
  height: 63px;
}

.cardWidth {
  width: 380px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.tooltipStyles {
  padding: 8px !important;
  max-width: 175px !important;
  background-color: #fff !important;
  opacity: 1 !important;
  @include highlight14($basicDark);
  color: $basicDark !important;
}

.disabledBtn {
  background: #330e52 !important;
  color: #989898 !important;
}
