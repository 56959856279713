@import '../../../assets/styles/mixins';

.CardWrapper {
  max-width: 380px;
  box-shadow: 0 0 24px rgba(60, 54, 54, 0.16);
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.5s ease;
  background-color: rgba(48, 12, 90, 0.5);
  border-radius: 8px;
  border: 1px solid $pink2;

  &:hover {
    box-shadow: 0 0 24px rgba(60, 54, 54, 0.5);
  }

  @media (max-width: $tablet) {
    max-width: none;
  }

  .Card {
    padding: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .freeze {
      cursor: default;
    }

    .Image {
      width: 100%;
      margin-bottom: 12px;
    }

    .Name {
      width: 100%;
      .NameText {
        width: 200px;
        margin-bottom: 12px;
        white-space: nowrap;

        overflow: hidden;
        text-overflow: ellipsis;

        @include subheading24($basicWhite);
      }
    }

    .SubnameText {
      @include heading16($basicWhite);
    }

    .Author {
      display: flex;
      align-items: center;
      .AuthorText {
        color: #221e1e;
        font-size: 16px;
        line-height: 24px;
        overflow: hidden;
        width: 200px;
        text-overflow: ellipsis;
      }

      .Avatar {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        border-radius: 50%;
      }
    }
  }

  .Actions {
    padding: 14px 16px;
    background: transparent;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ae00ff;

    .Price {
      .Title {
        color: #6f6767;
        font-size: 16px;
        line-height: 24px;
      }

      .Value {
        color: #221e1e;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .Time {
      .Title {
        color: #6f6767;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
      }

      .Value {
        color: #221e1e;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
      }
    }
  }
}

.flexRow {
  display: flex;
}

.statusText {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 16px;
  border-top: 1px solid #ae00ff;

  @include heading20($basicWhite);

  &Green {
    color: $success;
    path {
      fill: $success;
    }
  }

  &Gold {
    color: $gold;
    path {
      fill: $gold;
    }
  }

  &None {
    display: none;
  }
}

.imgWrapper {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 22px;
  padding: 10px;
  padding-bottom: 0;
  height: 339px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
