@import '../../../assets/styles/mixins';

$mobile: 620px;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 40px 0;
}

.pageWrapper {
  background-color: #FFFFFF;
  background-image: url('../../../assets/img/edit_profile_background.png');
  background-position: 0 -80px;
  background-repeat: no-repeat;
  height: 100%;
}

.pageLayout {
  background-color: transparent;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 59px);
}

.padding24 {
  padding: 24px !important;
}

.flexRow {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.wrap {
  border: 2px solid #EBEAEA;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  padding: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 408px;
  position: relative;
}

.wrapDrag {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #EBEAEA;
  opacity: 0.5;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;
}

.description {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6F6767;
  text-align: center;
}

.fileUpload {
  label {
    width: fit-content;
    background-color: #FBBFB1;
    border-width: 0;
    padding: 12px 24px;
    border-radius: 48px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #221E1E;
    transition: 0.4s;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 24px;
  }

  span {
    margin-left: 8px;

  }

  input[type='file'] {
    display: none;
  }
}

.leftBlock {
  display: flex;
  align-items: center;

  .imageWrapper {
    height: 300px;
    width: 300px;
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploadImage {
    max-height: 100%;
    max-width: 100%;
  }

  .textBlock {
    display: flex;
    flex-direction: column;
  }

  .fileName {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #221E1E;
  }

  .fileType {
    font-weight: 700;
    font-size: 14px;
    color: #221E1E;
  }
}

.rightBlock {
  .redIcon {
    path {
      fill: #CD3B1B;
    }
  }
  .action {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #554F4F;
    display: flex;
    cursor: pointer;
    span {
      margin: 3px 0 0 6px;
    }
  }

  .redAction {
    color: #CD3B1B !important;
    margin-top: 16px;
  }
}

.link {
  margin: 12px 0 75px ;
}

.formLeftBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 282px;
}

.formWrapper {
  margin: -20px -20px -20px 20px;
}

.button {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 10px 15px !important;
  margin-top: 24px;
}

.Input {
  width: 478px !important;
}

.TextAreaWrap {
  padding: 20px;
}

.Textarea {
  &:focus {
    border: 2px solid #221E1E !important;
    color: #221E1E !important;
  }
}

.selectWrap {
  padding: 0 20px;
  .select {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 2px solid #948C8C !important;
    margin-bottom: 4px !important;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 20px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .dropdown {
    right: 0 !important;
  }
}

.progressBar {
  width: 480px;
  margin: 16px 0 24px;
}
