$mobile: 743px;

.tags {
  margin: 15px 30px -6px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;

  @media (max-width: $mobile) {
    margin-left: 40px;
  }

  .tag {
    //margin: 6px 6px;
  }
  .clear__all {
    color: #A34F3D;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
}
