.container {
  margin: 0 auto;
  box-shadow: 0 0 24px rgb(60 54 54 / 16%);
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 200px;
  color: #221E1E;
  background-color: #FFFF;
  padding: 40px;
  display: flex;
  gap: 40px;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {
  width: 478px;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.productCard {
  [class*=card__img] {
    width: 250px;
  }
}

.right__content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.title {
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;
  color: #221E1E;
}

.description {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  color: #3C3636;
}
.link {
  margin: 24px auto 0 auto;

  text-decoration: none;
}