@import '../../../assets/styles/mixins';

.container {
  padding: 24px;
  border: 1px solid $pink2;
  border-radius: 8px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  gap: 50px;

  &NoGap {
    gap: 0px;
    margin-bottom: 0;
  }

  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.subtitle {
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #d3d2d4;
  @include heading14($grayscale);

  &Big {
    text-transform: none;
    @include subheading24($basicWhite);
  }
}

.timer {
  @include subheading24($basicWhite);
}

.text {
  @include subheading24($basicWhite);
  .normal {
    @include body16($basicWhite);
  }
}
