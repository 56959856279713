@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.Message {
  display: flex;
  align-items: center;
  margin-top: 12px;
  @include heading16();

  .Icon {
    margin-right: 8px;
  }

  &.success {
    color: $success;
  }

  &.neutral {
    color: $grayscale;
  }

  &.error {
    color: $error;
  }
}
