$mobile: 620px;

.cropper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(60, 54, 54, 0.8);
  backdrop-filter: blur(6px);
  z-index: 1000;
}

.square {
  height: 50px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
  width: 50px;
}

.crop_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 600px;
  overflow: hidden;

  @media (max-width: $mobile) {
    width: 90%;
  }
}

.crop_content {
  flex: 1;
  width: 100%;
  position: relative;
}

.crop_menu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100px;
}