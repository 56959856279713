@import '../../../assets/styles/mixins';

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 40px;
  background: #8101eb;
  border-radius: 48px;

  @include highlight16($basicWhite);
}
