@import '../../../assets/styles/global.scss';

.paper {
  box-shadow: 0 0 24px rgb(60 54 54 / 16%);
  border-radius: 8px;
  width: -moz-fit-content;
  // width: fit-content;
  width: 100%;
  max-width: 880px;
  min-height: 200px;
  color: #221E1E;
  background-color: #FFFF;
  padding: 40px;
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.title {
  font-size: 24px;
  line-height: 40px;
}

.step {
  background-color: #FFF1EE;
  border-width: 0;
  padding: 8px 16px;
  border-radius: 48px;
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #221E1E;
  transition: 0.4s;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
