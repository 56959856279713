.accordion {
  padding-bottom: 16px;
  .accordion__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #6F6767;
    padding: 2px;
    cursor: pointer;
    margin-bottom: 16px;
  }
  .accordion__content {
    display: flex;
    flex-direction: column;
    height: 0;
    opacity: 0;
    gap: 8px;
    transition: all 0.4s ease-in-out;
    max-height: 400px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #948C8C;
      border-radius: 8px;
    }
    .accordion__item {
      display: flex;
      padding: 16px 2px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EBEAEA;
      .items__left {
        display: flex;
        gap: 16px;
        & > img {
          width: 48px;
          height: 48px;
        }
        .left__info {
          .left__info_address {
            font-weight: 600;
            color: #554F4F;
            font-size: 16px;
            line-height: 24px;
            & > b {
              color: #000000;
              font-weight: 700;
            }
          }
          .left__date {
            color: #948C8C;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      .items__right {
        display: flex;
        align-items: center;
        gap: 24px;
        & > div {
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
        }
        & > svg {
          cursor: pointer;
        }
        & > svg > path {
          fill: #554F4F;
        }
      }
    }
  }
}
.rotate {
  transform: rotate(-180deg);
  transition: 0.4s;
}
.accordion__active {
  height: auto !important;
  opacity: 1 !important;
}
