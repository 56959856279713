$mobile: 743px;

.check__content_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: #000;
  padding: 100px;

  & > button {
    margin-top: 24px;
  }
  .check__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
  }
  .check__description {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    @media (max-width: $mobile) {
      text-align: center;
    }
  }
}

.feed__list {
  margin: 0 30px 54px 30px;
}
