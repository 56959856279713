@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.page_list {
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 36px 24px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: calc($tablet - 150px)) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
    > * {
      max-width: 400px;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.not_found {
  @include heading20($basicWhite);
}
